<template>
  <qrcode-vue :value="value" :size="size" level="H" />
</template>
<script>
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'qr-code-generator',
  data() {
    return {};
  },
  methods: {},
  computer: {},
  components: {
    QrcodeVue,
  },
  props: {
    size: {
      type: Number,
      default: 300,
    },
    value: {
      type: String,
      default: '',
    },
  },
};
</script>
